<template>
    <div style="background-color: #ffffff;padding: 20px">

        <div class="pageCenter text-left m-b-20" >


            <lineTitle text="订单发货信息"></lineTitle>
            <div class="m-b-40">
                <order :orderData="orderData" class="m-t-20"></order>
            </div>
            <lineTitle text="物流信息"></lineTitle>
            <el-form ref="form" :model="form" :rules="formRules" label-width="0px">
                <el-form-item label="">
                    <div class="bold m-t-40 f16"><span class="c-r">*</span> 发货方式</div>
                    <el-radio-group v-model="form.orderDeliverType" class="m-l-15">
                        <el-radio :label="2">物流</el-radio>
                        <el-radio :label="1">快递</el-radio>
                        <el-radio :label="0">其他方式</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" v-if="form.orderDeliverType>0">
                    <div class="bold f16"><span class="c-r">*</span> 快递信息</div>
                    <div class="df m-l-15">
                        <div>
                            <div v-if="form.orderDeliverType==2">选择物流</div>
                            <div v-else>选择快递</div>
                            <el-form-item label="" prop="orderDeliverCorporationName">
                                <el-select v-model="form.orderDeliverCorporationId" placeholder="请选择转载类型" style="width: 300px;" v-if="0">
                                    <el-option label="原创" :value="0"></el-option>
                                    <el-option label="投稿" :value="1"></el-option>
                                    <el-option label="转载" :value="2"></el-option>
                                </el-select>
                                <el-input v-model="form.orderDeliverCorporationName" style="width: 300px;"></el-input>
                            </el-form-item>
                        </div>
                        <div class="m-l-30">
                            <div v-if="form.orderDeliverType==2">物流单号</div>
                            <div v-else>快递单号</div>
                            <el-form-item label="" prop="orderDeliverCorporationNumber">
                                <el-input v-model="form.orderDeliverCorporationNumber" style="width: 300px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="" v-if="form.orderDeliverType==0">
                    <div class="bold f16"><span class="c-r">*</span> 物流信息</div>
                    <div class="df m-l-15">
                        <el-form-item prop="orderDeliverCorporationName">
                            <el-input v-model="form.orderDeliverCorporationName" style="width: 300px;"></el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">确认发货</el-button>
                    <el-button @click="$oucy.back()">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "refund",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            form: {
                orderDeliverType: 2,
                orderDeliverCorporationName: null,
                orderDeliverCorporationId: '123',
                orderDeliverCorporationNumber: null,
            },
            formRules: {

                orderDeliverCorporationName: [
                    { required: true, message: '请输入信息', trigger: 'blur' },
                ],
                orderDeliverCorporationNumber: [
                    { required: true, message: '请输入单号', trigger: 'blur' },
                ],
            },
            statu: 0,
            input3: null,
            select: null,
            queryFurnitureLibraryObj: {},
            input: null,
            orderId: null,
            splitOrder: null,

            orderData: {},
        }
    },
    computed: {

    },
    mounted() {
        this.orderId = this.$route.query.orderId
        this.splitOrder = this.$route.query.splitOrder=='true'?true:false
        this.queryOrderDetail()
    },
    methods: {
        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            // me.queryFurnitureLibrary();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            // me.queryFurnitureLibrary();
        },
        // 请求
        queryOrderDetail() {
            schemeOrder.queryOrderDetail({ orderId: this.orderId, splitOrder: this.splitOrder }).then(res => {
                this.orderData = res
                // this.detail=res&&res.orderSchemes&&res.orderSchemes[0]
            })
        },
        // 发货
        deliverOrder(){
            this.form.enterpriseOrderId=this.orderId
            schemeOrder.deliverOrder(this.form).then(res=>{
                // console.log(res)
                this.$alert('发货成功', '提示', {
                          confirmButtonText: '确定',
                          callback: action => {
                            this.$oucy.back()
                          }
                        });
            })
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.$oucy.keywordFiltr(this.form).then(res=>{
                        this.deliverOrder()
                    },err=>{})
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}

.c-r {
    color: #f00;
}
</style>